import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgHombreVolando, ImgHombreVolando} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft, ArrowNavRightCreditos} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import useSound from 'use-sound';
import {SpeechBubble} from '../../SpeechBubbles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import imgHombreVolando from '../../../images/obras/obra-15/hombre-volando.png';
import soundHombrevolando from '../../../images/obras/obra-15/hombre-volando.wav';
import imgLeft from '../../../images/obras/obra-14/obra.jpeg';
import imgRight from '../../../images/space.png';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 'creditos',
  bottom: null,
  left: 14,
};

const Page15Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMenFly, setInitAnimatedMenFly] = useState(false);
  const [initSoundMenFly, setInitSoundMenFly] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-15/obra.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };
  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>El breve sol enciende el hielo, en estanques y zanjas</div>
            <div>en un frío sin viento que es el calor del corazón.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnParrilla = () => {
    console.log('fnParrilla');
  }
  const fnReLaunchAll = () => {
    fnReLaunchHombreVolando(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchHombreVolando = (noReplay = 1) => {
    setInitAnimatedMenFly(false);
    setInitSoundMenFly(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMenFly(true);
      }, 1000);
    }
  };

  const fnAnimateHombreVolando = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchHombreVolando()}>
        {
          initAnimatedMenFly &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre Volando"
          />
        }
      </ContImgHombreVolando>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombrevolando,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMenFly(true);
    }, 3000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 12000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);

    const winWidth = window.innerWidth;
    let arPosMenFly = {};

    if(winWidth < 767){
      arPosMenFly = {x: -200, y: 420}
    } else if(winWidth < 1023){
      arPosMenFly = {x: -400, y: 780}
    } else if(winWidth < 1215){
      arPosMenFly = {x: -450, y: 1030}
    } else if(winWidth < 1407){
      arPosMenFly = {x: -500, y: 450}
    } else if(winWidth < 1919){
      arPosMenFly = {x: -500, y: 620}
    } else {
      arPosMenFly = {x: -600, y: 750}
    }

    if(initAnimatedMenFly){
      gsap.to(".animate-hombre-volando", {
        x: arPosMenFly.x,
        y: arPosMenFly.y,
        duration: 3,
        delay: 0,
        rotation: function(i, target, list) {
          return -360;
        },
      });

      if(!initSoundMenFly){
        playHombreVolando();
        setInitSoundMenFly(true);
      }
    }
  }, [initAnimatedMenFly]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateHombreVolando()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && arNav.right === 'creditos' ? <ArrowNavRightCreditos toPage={arNav.right}/> : <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page15Comp;
