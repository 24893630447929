import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -180px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -350px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -300px;
      margin-left: -460px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: -270px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -380px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -440px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  position: absolute;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 140px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: 0%;
    left: 5%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 240px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: 0%;
    left: 40%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 310px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: 0%;
    left: 50%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 210px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: -5%;
    left: 50%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 260px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: 0%;
    left: 50%;
  }
  @media(min-width: 1920px){
    width: 310px;
    height: auto;
    margin-top: 0px;
    margin-left: 200px;
    top: 0%;
    left: 50%;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
